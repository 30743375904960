import BlogGridCard from "@components/blog/blogGridCard";
import { Button } from "@components/global";
import CategoryPanelPreview from "@components/global/categoryPanelPreview";
import CtaBanner from "@components/global/ctaBanner";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import RecipeCard from "@components/global/recipeCard";
import { Query } from "@graphql-types";
import Layout from "@shared/layout";
import { useStore } from "@state/store";
import { colorsRGB } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { Container, GridContainer, H1, H3, P } from "@util/standard";
import { isSanityCategoryPanelPreview, isSanityCtaBanner, isSanityLearnCategoryPreview } from "@util/types";

import { navigate, graphql, PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

interface Props extends PageProps {
  data: Query;
}

function MySaved({ data }: Props) {
  const { user, savedRecipes, savedBlogs } = useStore();
  const [currentMenuOpen, setCurrentMenuOpen] = useState("recipes");
  const { isLaptopWidth } = useCheckScreenWidth();
  if (data == null || data.sanityMySaved == null) {
    return null;
  }

  const { title, pageText, savedRecipesPageContent, savedBlogsPageContent } = data.sanityMySaved;
  const allRecipes = data.allSanityRecipe.nodes;
  const allBlogs = data.allSanityBlog.nodes;

  useEffect(() => {
    if (user == null) {
      navigate("/");
    }
  });

  const isRecipes = currentMenuOpen === "recipes";
  const isBlogs = currentMenuOpen === "blogs";

  const filterSavedRecipes = (id: String) => {
    const recipeMatch = allRecipes.filter(recipe => {
      return recipe._id === id;
    });
    return recipeMatch;
  };

  const filterSavedBlogs = (id: String) => {
    const blogMatch = allBlogs.filter(blog => {
      return blog._id === id;
    });
    return blogMatch;
  };
  return (
    <>
      <Layout>
        <Container flexDirection="column" margin="220px 0 0 0">
          <Container
            flexDirection="column"
            width="65%"
            tabletWidth="80%"
            margin="0 auto 140px auto"
            tabletMargin="0 auto 100px auto"
          >
            {title && <H1 color="darkerGreen">{title}</H1>}
            {pageText && <P width={isLaptopWidth ? "58%" : "100%"}>{pageText}</P>}
            <Container margin="40px 0 0 0">
              <Container
                backgroundRGBColor={isRecipes ? colorsRGB.mossGreen(1) : colorsRGB.mossGreen(0.3)}
                onClick={() => setCurrentMenuOpen("recipes")}
                cursor="pointer"
              >
                <P color="white" margin="12px 50px">
                  Recipes
                </P>
              </Container>
              <Container
                backgroundRGBColor={isBlogs ? colorsRGB.mossGreen(1) : colorsRGB.mossGreen(0.3)}
                onClick={() => setCurrentMenuOpen("blogs")}
                cursor="pointer"
              >
                <P color="white" margin="12px 50px">
                  Articles
                </P>
              </Container>
            </Container>
          </Container>
          {isRecipes && (
            <Container margin="0 0 120px 0" tabletMargin="0 0 60px 0" flexDirection="column">
              {savedRecipes && savedRecipes.length > 0 && (
                <GridContainer
                  width="88%"
                  tabletWidth="80%"
                  margin="auto"
                  columnGap="15px"
                  rowGap={isLaptopWidth ? 60 : 15}
                >
                  {savedRecipes.map(recipeId => {
                    const recipeData = filterSavedRecipes(recipeId);
                    if (recipeData == null) {
                      return null;
                    }
                    return <RecipeCard recipeId={recipeId} data={recipeData[0]} />;
                  })}
                </GridContainer>
              )}
              {savedRecipes && savedRecipes.length === 0 && (
                <Container width="65%" margin="auto" flexDirection="column">
                  <H3 color="darkerGreen">No saved Recipes</H3>
                  <Button linkTo="/recipes" theme="block" text="View Recipes" />
                </Container>
              )}
            </Container>
          )}
          {isBlogs && (
            <Container margin="0 0 120px 0" tabletMargin="0 0 60px 0" flexDirection="column">
              {savedBlogs && savedBlogs.length > 0 && (
                <GridContainer
                  width="88%"
                  margin="auto"
                  tabletWidth="80%"
                  repeat={3}
                  columnGap="15px"
                  rowGap={isLaptopWidth ? 60 : 15}
                >
                  {savedBlogs.map(blogId => {
                    const blogData = filterSavedBlogs(blogId);
                    if (blogData == null) {
                      return null;
                    }
                    return <BlogGridCard blogId={blogId} data={blogData[0]} key={blogData[0]._key} />;
                  })}
                </GridContainer>
              )}
              {savedBlogs && savedBlogs.length === 0 && (
                <Container width="65%" margin="auto" flexDirection="column">
                  <H3 color="darkerGreen">No saved Blogs</H3>
                  <Button linkTo="/learn" theme="block" text="View Blogs" />
                </Container>
              )}
            </Container>
          )}
          {currentMenuOpen === "recipes" && savedRecipesPageContent && (
            <div>
              {savedRecipesPageContent.map(data => {
                if (isSanityCtaBanner(data)) {
                  return <CtaBanner data={data} key={data._key} />;
                }
                if (isSanityCategoryPanelPreview(data)) {
                  return <CategoryPanelPreview data={data} key={data._key} />;
                }

                if (isSanityLearnCategoryPreview(data)) {
                  return <LearnCategoryPreview data={data} key={data._key} />;
                }
                return null;
              })}
            </div>
          )}
          {currentMenuOpen === "blogs" && savedBlogsPageContent && (
            <div>
              {savedBlogsPageContent.map(data => {
                if (isSanityCtaBanner(data)) {
                  return <CtaBanner data={data} key={data._key} />;
                }
                if (isSanityCategoryPanelPreview(data)) {
                  return <CategoryPanelPreview data={data} key={data._key} />;
                }

                if (isSanityLearnCategoryPreview(data)) {
                  return <LearnCategoryPreview data={data} key={data._key} />;
                }
                return null;
              })}
            </div>
          )}
        </Container>
      </Layout>
    </>
  );
}

export default MySaved;

export const query = graphql`
  {
    sanityMySaved {
      pageText
      title
      savedRecipesPageContent {
        ... on SanityLearnCategoryPreview {
          ...sanityLearnCategoryPreview
        }
        ... on SanityCtaBanner {
          _key
          _type
          ctas {
            ...sanityCta
          }
          bannerText {
            ...sanityColumn
          }
        }
        ... on SanityCategoryPanelPreview {
          ...sanityCategoryPanelPreview
        }
      }
      savedBlogsPageContent {
        ... on SanityLearnCategoryPreview {
          ...sanityLearnCategoryPreview
        }
        ... on SanityCtaBanner {
          _key
          _type
          ctas {
            ...sanityCta
          }
          bannerText {
            ...sanityColumn
          }
        }
        ... on SanityCategoryPanelPreview {
          ...sanityCategoryPanelPreview
        }
      }
    }
    allSanityRecipe {
      nodes {
        ...sanityRecipe
      }
    }
    allSanityBlog {
      nodes {
        ...sanityBlog
      }
    }
  }
`;
